import React from "react"
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"
import ReactTooltip from "react-tooltip"
import { defineMessages } from "gatsby-plugin-intl"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  sortingOption: "Sort column. (Words are sorted alphabetically, numbers by value and dates by time.",
})

const Attribute = ({ header, onSort, sortDirection }) => {
  const formatMessage = useFormatMessage()
  const { regular } = useThemeColors()

  let SortableIcon = <FaSort className="opacity-25" size={12} />

  if (sortDirection === "asc") {
    SortableIcon = <FaSortUp className={`text-${regular}`} size={12} />
  }

  if (sortDirection === "desc") {
    SortableIcon = <FaSortDown className={`text-${regular}`} size={12} />
  }

  const isSorted = sortDirection === "asc" || sortDirection === "desc"

  return (
    <>
      <ReactTooltip id={`sorting ${header.text}`} place="top" type="dark" effect="solid" className="max-w-xs">
        {formatMessage(messages.sortingOption)}
      </ReactTooltip>

      <div
        className={`text-center cursor-pointer  md:${header.width} ${
          header.mobileWidth ? header.mobileWidth : "hidden md:block"
        }   py-3 uppercase font-semibold break-all select-none flex flex-row justify-center`}
        onClick={header.sortableIcon === false ? () => {} : onSort}
      >
        <a data-for={`sorting ${header.text}`} data-tip>
          <div className="flex flex-row items-center justify-center text-xs space-x-1">
            {header.text && (
              <span className={isSorted ? "text-gray-900 font-semibold" : "text-gray-600 font-medium"}>{formatMessage(header.text)}</span>
            )}
            {header.text && header.sortableIcon !== false && SortableIcon}
          </div>
        </a>
      </div>
    </>
  )
}

export default Attribute
