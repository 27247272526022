import React from "react"

const PageNumber = ({ className, number, onClick, selected }) => {
  return (
    <div
      className={`cursor-pointer text-xs w-8 h-8 flex rounded items-center justify-center hover:bg-gray-200 ${
        selected ? "font-black" : "font-semibold"
      }  ${className}`}
      onClick={onClick}
    >
      {number}
    </div>
  )
}

export default PageNumber
