import React from "react"
import _ from "lodash"
import ReactTooltip from "react-tooltip"
// Row Interface

// const row = [
//   {
//     text: "Test",
//     width: "w-1/2"
//     mobileWidth?: "w-2/3",
//   }, ...
// ]

const TableRow = ({ row, onRowClick }) => {
  /* Filter out uuid from row data */
  /* This will be used to be able to show participant in modal when a participant is clicked 
     We use either the person UUID (for level evaluations) or the uuid of the object */
  const person = _.find(row, "personuuid")
  const id = _.find(row, "uuid")
  let uuid
  if (id) {
    uuid = id.uuid
  }
  let personuuid
  if (person) {
    personuuid = person.personuuid
  }

  return (
    <div className="flex">
      <div className={`group ${onRowClick ? "hover:bg-gray-100" : ""}  flex  w-full`}>
        {row &&
          row.map((tableCell, idx) => (
            <div
              key={`${tableCell.text}${idx}`}
              className={`${tableCell.mobileWidth ? tableCell.mobileWidth : "hidden md:block"} ${
                onRowClick ? "cursor-pointer" : ""
              } text-xs text-center md:${tableCell.width} px-1 py-2 text-sm max-w-sm my-auto`}
              onClick={() => !tableCell.notClickable && onRowClick && onRowClick(personuuid || uuid)}
            >
              {!tableCell.noTooltip && (
                <ReactTooltip id={`${idx}-tt-${tableCell.text}`} place="top" type="dark" effect="solid" className="max-w-sm">
                  {tableCell.text}
                </ReactTooltip>
              )}
              <a data-for={`${idx}-tt-${tableCell.text}`} data-tip>
                {tableCell.render ? tableCell.render(tableCell) : tableCell.text}
              </a>
            </div>
          ))}
      </div>
    </div>
  )
}

export default TableRow
