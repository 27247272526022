import React, { useState } from "react"
import { IoMdClose } from "react-icons/io"
import ReactTooltip from "react-tooltip"
import { defineMessages } from "gatsby-plugin-intl"
import ConfirmModal from "../../../base/modals/confirmModal"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  deleteTooltip: "Remove",
  confirmDeleteMessage: "Are you sure?",
})

const DeleteRowAction = ({ onDelete, confirmText, confirmTitle = messages.confirmDeleteMessage, tooltip = messages.deleteTooltip }) => {
  const formatMessage = useFormatMessage()
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  return (
    <React.Fragment>
      <div className="text-red-500 flex items-center h-full ml-2 cursor-pointer">
        <ReactTooltip id="remove" place="top" type="error" effect="solid">
          {formatMessage(tooltip)}
        </ReactTooltip>
        <a data-for="remove" data-tip>
          <IoMdClose size={20} onClick={() => setConfirmingDelete(true)} />
        </a>
      </div>
      <ConfirmModal
        visible={confirmingDelete}
        title={formatMessage(confirmTitle)}
        confirmText={formatMessage(confirmText)}
        onConfirm={() => {
          setConfirmingDelete(false)
          onDelete()
        }}
        onClose={() => setConfirmingDelete(false)}
      />
    </React.Fragment>
  )
}

export default DeleteRowAction
