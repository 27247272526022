import _ from "lodash"

export const parseClients = data => {
  const res = _.flatten(
    data.map(d => {
      const parsedElement = {
        ...d,
        sellerfullname: "Not available",
        selleremail: "Not available",
      }

      return parsedElement
    })
  )
  return res
}
export const searchClients = (searchQuery, clients) => {
  const queryStringsArray = searchQuery.split(" ")
  let result = clients
  queryStringsArray.forEach(queryString => {
    result = _.filter(clients, o => {
      return (
        o.uuid.toLowerCase().includes(queryString.trim().toLowerCase()) ||
        o.companyname.toLowerCase().includes(queryString.trim().toLowerCase()) ||
        o.sellerfullname.toLowerCase().includes(queryString.trim().toLowerCase()) ||
        o.selleremail.toLowerCase().includes(queryString.trim().toLowerCase())
      )
    })
  })
  return result
}
