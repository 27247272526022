import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import DeleteHrManagerAction from "../components/deleteHrManagerAction"

const messages = defineMessages({
  nameHeader: "HR-Manager",
  emailHeader: "Email",
  languageHeader: "Language",
  functionHeader: "Function",
})

export default [
  {
    id: "fullname",
    text: messages.nameHeader,
    width: "w-4/12",
    mobileWidth: "w-4/12",
    extractAttribute: data => data.firstname + " " + data.lastname,
  },

  {
    id: "email",
    text: messages.emailHeader,
    dataKey: "email",
    width: "w-3/12",
    mobileWidth: "w-3/12",
    extractAttribute: data => data.email,
  },
  {
    id: "commlangcode",
    text: messages.languageHeader,
    dataKey: "commlangcode",
    width: "w-2/12",
    mobileWidth: "w-2/12",
    extractAttribute: data => data.commlangcode,
  },
  {
    id: "theirref2",
    text: messages.functionHeader,
    dataKey: "theirref2",
    width: "w-2/12",
    mobileWidth: "w-2/12",
    extractAttribute: data => data.theirref2,
  },
  {
    id: "action",
    width: "w-1/12",
    mobileWidth: "w-1/12",
    noTooltip: true,
    notClickable: true,
    render: ({ data }) => {
      return <DeleteHrManagerAction hrManager={data} />
    },
  },
]
