import React from "react"
import TableHeader from "./components/header"
import TableRow from "./components/row"
import { linkHeaderToData } from "./utils/utils"
import Loader from "../../loaders/loader"

const Table = ({ data, headers, className, onRowClick, onColumnSortClicked, sortedColumns, loading, noGreenLine }) => {
  let mappedData
  if (data) {
    mappedData = data.map(row => {
      return linkHeaderToData(headers, row)
    })
  }

  return (
    <div className={`w-full shadow border rounded-t bg-white ${className}`}>
      <TableHeader headers={headers} sortedColumns={sortedColumns} onColumnSortClicked={onColumnSortClicked} noGreenLine={noGreenLine} />
      <div className="py-4">
        {loading && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}
        {!loading && mappedData && mappedData.map(row => <TableRow key={JSON.stringify(row)} row={row} onRowClick={onRowClick} />)}
        {!loading && (!mappedData || mappedData.length === 0) && (
          <div className="h-full w-full flex justify-center items-center my-5 text-gray-400">No results</div>
        )}
      </div>
    </div>
  )
}

export default Table
