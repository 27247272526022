export const calculateNewSortedColumns = (header, sortedColumns) => {
  const previousSortIndex = sortedColumns.findIndex(s => s.header.id === header.id)

  // CASE 1: no sorting yet on this column
  if (previousSortIndex === -1) {
    return [{ header, direction: "asc" }]
  }

  // CASE 2: sorted already in ascending order, change to descending
  if (sortedColumns[previousSortIndex].direction === "asc") {
    return [{ header, direction: "desc" }]
  }

  // CASE 3: sorted already in descending order, remove sorting on this column
  return []
}

export const sortData = (data, sortedColumns) => {
  if (!data || !Array.isArray(data)) return []
  return data.sort((a, b) => {
    // Loop over sorted columns
    for (let i = 0; i < sortedColumns.length; i++) {
      const { header, direction } = sortedColumns[i]
      if (!header.extractAttribute) {
        return 0
      }

      const asc = direction === "asc"

      // CASE 1: custom comparator is defined
      if (header.comparator) {
        const value = header.comparator(a, b)

        // Only return if we can make a decision based on this comparator
        if (value !== 0) {
          return asc ? value : -value
        }
      }

      const aAttr = header.extractAttribute(a)
      const bAttr = header.extractAttribute(b)

      // CASE 2: no comparator is defined and we're comparing numbers
      // Sanitize first
      if (!isNaN(aAttr) && !isNaN(bAttr)) {
        const value = aAttr - bAttr

        // Only return if we can make a decision based on this comparator
        if (value !== 0) {
          return asc ? value : -value
        }
      }

      // CASE 3: no comparator is defined and it's not a number, fall back to string compare
      // Sanitize first
      let aString
      if (aAttr === null || String(aAttr)?.trim() === "") {
        aString = String("Z")
      } else {
        aString = String(aAttr).toLowerCase()
      }

      let bString
      if (bAttr === null || String(bAttr)?.trim() === "") {
        bString = String("Z")
      } else {
        bString = String(bAttr).toLowerCase()
      }

      const value = aString.localeCompare(bString)

      // Only return if we can make a decision based on this comparator
      if (value !== 0) {
        return asc ? value : -value
      }
    }

    return 0
  })
}
