import validate from "validate.js"

export const getErrors = state => {
  return state.map(contactPerson => isValidPerson(contactPerson))
}

export const hasErrors = errors => {
  return errors.map(person => Object.values(person).filter(e => !!e)).filter(e => e.length > 0).length > 0
}

const isValidPerson = person => ({
  firstname: validateName(person.firstname),
  lastname: validateName(person.lastname),
  email: isValidEmail(person.email),
  commlangcode: !isValidLanguage(person.commlangcode),
})

const validateName = name => {
  const errors = []
  if (validate.isEmpty(name)) {
    errors.push("nameRequiredError")
  }
  if (!validate.isString(name)) {
    errors.push("nameShouldBeTextError")
  }
  if (errors.length > 0) {
    return errors
  }
  return false
}

//This method is not working, should be tested
const isValidEmail = email => {
  const res = validate({ email }, { email: { email: true } })
  if (res) {
    return ["emailInvalidError"]
  } else {
    return false
  }
}

const allowedLanguageCodes = ["fr", "en", "de", "nl"]

const isValidLanguage = language => allowedLanguageCodes.includes(language)
