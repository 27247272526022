import React from "react"
import DeleteRowAction from "../../../base/paginated-table/deleteRowAction"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { defineMessages } from "gatsby-plugin-intl"
import useDeleteCompany from "../../../../utils/hooks/useDeleteCompany"
import notifications from "../../../../utils/notifications"

const messages = defineMessages({
  deleteClientSuccessNotificationTitle: "Client deleted!",
  deleteClientSuccessNotificationMessage: "You have successfully deleted this client",
  deleteClientErrorNotificationTitle: "Oops! Something went wrong.",
  deleteClientConfirmMessage: "Are you sure you want to delete this client? This action cannot be undone!",
  deleteClientConfirmButton: "Confirm",
})

const DeleteCompanyAction = ({ company }) => {
  /* Delete HR Manger */
  const { deleteCompany } = useDeleteCompany()

  const formatMessage = useFormatMessage()

  const onDelete = async () => {
    try {
      await deleteCompany(company.uuid)
      notifications.success(
        formatMessage(messages.deleteClientSuccessNotificationTitle),
        formatMessage(messages.deleteClientSuccessNotificationMessage)
      )
    } catch (error) {
      notifications.error(formatMessage(messages.deleteClientErrorNotificationTitle), error.message, error.details)
    }
  }

  return <DeleteRowAction onDelete={onDelete} confirmTitle={messages.deleteClientConfirmMessage} confirmText={messages.deleteClientConfirmButton} />
}

export default DeleteCompanyAction
