import React, { useState } from "react"
import { useFetching } from "../../../utils/hooks/useFetch"
import _ from "lodash"
import { fetchCompany } from "../../../store/company/actions"
import { companySelector } from "../../../store/company/selectors"
import { useSelector } from "react-redux"
import { getAndParseHrManagersFromCompany } from "./utils/utils"
import headers from "./utils/headers"
import PaginatedTable from "../../base/paginated-table"
import CompanyInfo from "./components/companyInfo"
import CreateHrManagerModal from "./components/createHrManagerModal"
import EditHrManagerModal from "./components/editHrManagerModal"
import Button from "../../base/buttons/regular"
import ModalContainer from "../../base/modals/modalContainer"

import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import { defineMessages } from "gatsby-plugin-intl"

const messages = defineMessages({
  clientAddNewHrManagerButton: "Add new HR Managers",
})

const ClientModal = ({ clientUUID, visible, onClose, className }) => {
  const [addHrManagers, setAddHrManagers] = useState(false)
  const [editHrManager, setEditHrManager] = useState()

  const formatMessage = useFormatMessage()
  /* Fetch the client */
  useFetching(fetchCompany(clientUUID), clientUUID)
  /* Get company from store */
  const { result: company, loading } = useSelector(companySelector(clientUUID))
  const handleSetEditHrManager = uuid => {
    const hrManager = company.hrmanagers.find(hrManager => hrManager.uuid === uuid)
    setEditHrManager(hrManager)
  }

  return (
    <>
      <ModalContainer visible={visible} className={`md:w-5/3 ${className}`} onClose={onClose}>
        <div className="flex flex-col items-center w-full mb-6">
          {company && <CompanyInfo company={company} />}
          <div className="flex flex-col justify-center w-full">
            <div className="flex flex-col mx-5  lg:w-auto rounded ">
              {
                <PaginatedTable
                  data={getAndParseHrManagersFromCompany(_.get(company, "hrmanagers", []))}
                  headers={headers}
                  className="border"
                  noGreenLine
                  loading={loading}
                  onRowClick={handleSetEditHrManager}
                />
              }
            </div>
            <Button className="w-64 self-center mt-8" primary onClick={() => setAddHrManagers(true)}>
              {formatMessage(messages.clientAddNewHrManagerButton)}
            </Button>
          </div>
          {addHrManagers && <CreateHrManagerModal visible={addHrManagers} onClose={() => setAddHrManagers(false)} companyUUID={clientUUID} />}
          {editHrManager && <EditHrManagerModal onClose={() => setEditHrManager(false)} person={editHrManager} />}
        </div>
      </ModalContainer>
    </>
  )
}

export default ClientModal
