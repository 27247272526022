import React, { useEffect, useState } from "react"
import Title from "../../../../../layout/title"
import TextInput from "../../../../../base/forms/textInput"
import ModalContainer from "../../../../../base/modals/modalContainer"

import Label from "../../../../../base/forms/label"
import Button from "../../../../../base/buttons/regular"
import Loader from "../../../../../base/loaders/loader"
import usePatchCompany from "../../../../../../utils/hooks/usePatchCompany"
import notifications from "../../../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../../utils/hooks/useFormatMessage"
import { useEvaluationLimits } from "src/utils/evaluation-type-utils"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  updateClientSuccessNotificationTitle: "Client updated!",
  updateClientSuccessNotificationMessage: "You have successfully updated this client",
  updateClientErrorNotificationTitle: "Oops! Something went wrong.",
  updateClientTitle: "Edit {companyname}'s info",
  updateClientCompanyNameInputLabel: "Company name: ",
  updateClientMaximumEvaluationsInputLabel: "Max # evaluations:",
  updateClientMaximumEasyEvaluationsInputLabel: "Max # easy evaluations:",
  updateClientConfirmButton: "Confirm",
})

const EditCompanyInfoModal = ({ onClose, company }) => {
  const [client, setClient] = useState()
  const [loading, setLoading] = useState(true)

  const [getLimit, setLimit, initLimitsToCompany] = useEvaluationLimits()

  const formatMessage = useFormatMessage()

  useEffect(() => {
    setClient(company)
    initLimitsToCompany(company)
    setLoading(false)
  }, [])

  const handleChange = e => {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    })
  }

  const { patchCompany, loading: updateLoading } = usePatchCompany()
  /* Handle update of user information */
  const handleUpdate = async () => {
    try {
      await patchCompany({
        ...client,
        maxevaluationtypes: {
          [EvaluationType.EASY]: getLimit(EvaluationType.EASY),
          [EvaluationType.STANDARD]: getLimit(EvaluationType.STANDARD),
        },
      })
      notifications.success(
        formatMessage(messages.updateClientSuccessNotificationTitle),
        formatMessage(messages.updateClientSuccessNotificationMessage)
      )
      onClose()
    } catch (error) {
      notifications.error(formatMessage(messages.updateClientErrorNotificationTitle), error.message, error.details)
    }
  }
  return (
    <ModalContainer className="w-96" onClose={onClose}>
      <div className="flex flex-col px-12 pb-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Title>{formatMessage(messages.updateClientTitle, { companyname: company.companyname })}</Title>

            <Label className="mb-4" name={formatMessage(messages.updateClientCompanyNameInputLabel)}>
              <TextInput placeholder={company.companyname} onChange={handleChange} name="companyname" value={client.companyname} />
            </Label>

            <Label className="mb-4" name={formatMessage(messages.updateClientMaximumEvaluationsInputLabel)}>
              <TextInput
                type="number"
                placeholder={company.maxevaluationtypes[EvaluationType.STANDARD]}
                onChange={e => setLimit(EvaluationType.STANDARD, parseInt(e.nativeEvent.target.value, 10))}
                name="maxevaluations"
                value={getLimit(EvaluationType.STANDARD)}
              />
            </Label>

            <Label className="mb-4" name={formatMessage(messages.updateClientMaximumEasyEvaluationsInputLabel)}>
              <TextInput
                type="number"
                placeholder={company.maxevaluationtypes[EvaluationType.EASY]}
                onChange={e => setLimit(EvaluationType.EASY, parseInt(e.nativeEvent.target.value, 10))}
                name="maxevaluations"
                value={getLimit(EvaluationType.EASY)}
              />
            </Label>

            <Button primary className="self-center text-center w-3/4" onClick={handleUpdate} loading={updateLoading}>
              {formatMessage(messages.updateClientConfirmButton)}
            </Button>
          </>
        )}
      </div>
    </ModalContainer>
  )
}

export default EditCompanyInfoModal
