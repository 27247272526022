import React from "react"
import DeleteRowAction from "../../../base/paginated-table/deleteRowAction"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { defineMessages } from "gatsby-plugin-intl"
import useDeletePerson from "../../../../utils/hooks/useDeletePerson"
import notifications from "../../../../utils/notifications"

const messages = defineMessages({
  clientDeleteHrManagerSuccessNotificationTitle: "Person deleted!",
  clientDeleteHrManagerSuccessNotificationMessage: "You have successfully deleted this person.",
  clientDeleteHrManagerErrorNotificationTitle: "Oops! Something went wrong.",
  clientDeleteHrManagerConfirmMessage: "Are you sure you want to delete this person?",
  clientDeleteHrManagerConfirmButton: "Delete",
})

const DeleteHrManagerAction = ({ hrManager }) => {
  /* Delete HR Manger */
  const { deletePerson } = useDeletePerson()

  const formatMessage = useFormatMessage()

  const deleteHrManager = async () => {
    try {
      await deletePerson(hrManager.uuid, hrManager.companyuuid)
      notifications.success(
        formatMessage(messages.clientDeleteHrManagerSuccessNotificationTitle),
        formatMessage(messages.clientDeleteHrManagerSuccessNotificationMessage)
      )
    } catch (error) {
      notifications.error(formatMessage(messages.clientDeleteHrManagerErrorNotificationTitle), error.message, error.details)
    }
  }

  return (
    <DeleteRowAction
      onDelete={deleteHrManager}
      confirmTitle={messages.clientDeleteHrManagerConfirmMessage}
      confirmText={messages.clientDeleteHrManagerConfirmButton}
    />
  )
}

export default DeleteHrManagerAction
