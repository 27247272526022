import React, { useState, useEffect } from "react"
import _ from "lodash"
import Table from "./table"
import Pagination from "./pagination"
import { sortData, calculateNewSortedColumns } from "./utils"

const PaginatedTable = ({ data = [], loading, headers, onRowClick, rowsPerPage = 25, noGreenLine, searchQuery, className, defaultSort = [] }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sortedColumns, setSortedColumns] = useState(defaultSort) // Objects of type { header: { }, direction: 'asc' | 'desc' }

  const onColumnSortClicked = header => {
    const newSortedColumns = calculateNewSortedColumns(header, sortedColumns)
    setSortedColumns(newSortedColumns)
  }

  const sortedData = sortData(data, sortedColumns)

  const paginatedData = _.chunk(sortedData, rowsPerPage)
  const numberOfPages = paginatedData ? paginatedData.length : 1

  useEffect(() => {
    setCurrentPage(1)
  }, [searchQuery])

  return (
    <>
      <Table
        data={_.get(paginatedData, currentPage - 1, [])}
        headers={headers}
        onRowClick={onRowClick}
        sortedColumns={sortedColumns}
        onColumnSortClicked={onColumnSortClicked}
        loading={loading}
        className={className}
        noGreenLine={noGreenLine}
      />
      <Pagination numberOfPages={numberOfPages} currentPage={currentPage} onSelectPageNumber={setCurrentPage} />
    </>
  )
}

export default PaginatedTable
