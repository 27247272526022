import React from "react"
import PageNumber from "./components/page-number"

const Pagination = ({ numberOfPages, currentPage, onSelectPageNumber }) => {
  if (numberOfPages <= 1) {
    return null
  }

  if (numberOfPages <= 5) {
    return (
      <div className={`flex items-center self-center mt-5`}>
        {[...Array(numberOfPages).keys()].map(number => {
          const selected = currentPage === number + 1
          return (
            <div key={number} className={`flex items-center`}>
              <PageNumber
                className="mx-1 text-s text- px-2 text-center"
                number={number + 1}
                onClick={() => onSelectPageNumber(number + 1)}
                selected={selected}
              />
            </div>
          )
        })}
      </div>
    )
  }

  if (currentPage <= 3) {
    return (
      <div className={`flex items-center self-center mt-5`}>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={1} onClick={() => onSelectPageNumber(1)} selected={currentPage === 1} />
        </div>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={2} onClick={() => onSelectPageNumber(2)} selected={currentPage === 2} />
        </div>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={3} onClick={() => onSelectPageNumber(3)} selected={currentPage === 3} />
        </div>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={4} onClick={() => onSelectPageNumber(4)} selected={currentPage === 4} />
        </div>
        <span className="mx-2 pb-2 text-gray-500">...</span>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 1}
            onClick={() => onSelectPageNumber(numberOfPages - 1)}
          />
        </div>
      </div>
    )
  }

  if (currentPage >= numberOfPages - 3) {
    return (
      <div className={`flex items-center self-center mt-5`}>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={1} onClick={() => onSelectPageNumber(1)} selected={currentPage === 1} />
        </div>

        <span className="mx-2 pb-2 text-gray-500">...</span>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 4}
            onClick={() => onSelectPageNumber(numberOfPages - 4)}
            selected={currentPage === numberOfPages - 4}
          />
        </div>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 3}
            onClick={() => onSelectPageNumber(numberOfPages - 3)}
            selected={currentPage === numberOfPages - 3}
          />
        </div>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 2}
            onClick={() => onSelectPageNumber(numberOfPages - 2)}
            selected={currentPage === numberOfPages - 2}
          />
        </div>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 1}
            onClick={() => onSelectPageNumber(numberOfPages - 1)}
            selected={currentPage === numberOfPages - 1}
          />
        </div>
      </div>
    )
  }

  if (currentPage > 3 && currentPage < numberOfPages - 2) {
    return (
      <div className={`flex items-center self-center mt-5`}>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={1} onClick={() => onSelectPageNumber(1)} />
        </div>
        <span className="mx-2 pb-2 text-gray-500">...</span>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={currentPage - 1} onClick={() => onSelectPageNumber(currentPage - 1)} />
        </div>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={currentPage}
            onClick={() => onSelectPageNumber(currentPage)}
            selected={true}
          />
        </div>
        <div className={`flex items-center`}>
          <PageNumber className="mx-1 text-s text- px-2 text-center" number={currentPage + 1} onClick={() => onSelectPageNumber(currentPage + 1)} />
        </div>
        <span className="mx-2 pb-2 text-gray-500">...</span>
        <div className={`flex items-center`}>
          <PageNumber
            className="mx-1 text-s text- px-2 text-center"
            number={numberOfPages - 1}
            onClick={() => onSelectPageNumber(numberOfPages - 1)}
          />
        </div>
      </div>
    )
  }
}

export default Pagination
