import _ from "lodash"
import { getLanguageCode } from "../../../../utils/language"

export const getAndParseHrManagersFromCompany = data => {
  return data.map(hrm => ({
    ...hrm,
    fullname: `${hrm.firstname} ${hrm.lastname}`,
    email: hrm.email,
    commlangcode: getLanguageCode(hrm.commlangcode.toUpperCase()),
    theirref2: hrm.theirref2,
  }))
}

export const linkHeaderToData = (headers, row, columnsToSelect) => {
  // Here we select the object properties we want to inlcude in the table
  const dataToInclude = _.pick(row, columnsToSelect)
  const r = Object.keys(dataToInclude).map(key => {
    try {
      const header = _.find(headers, { dataKey: key })
      if (header.render) {
        return {
          text: dataToInclude[key],
          width: header.width,
          mobileWidth: header.mobileWidth,
          render: props => header.render(props),
        }
      }
      return {
        text: dataToInclude[key],
        width: header.width,
        mobileWidth: header.mobileWidth,
      }
    } catch (err) {
      return undefined
    }
  })
  r.push({ uuid: row.uuid })
  return r
}
