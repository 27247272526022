import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import DeletCompanyAction from "../components/deleteCompanyAction"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  companyHeader: "company",
  maxEvaluationsHeader: "Max Sessions",
  unusedEasy: "Unused EASY",
  unusedStandard: "Unused STANDARD",
  usedEvaluationsHeader: "Used # sessions",
  unusedEvaluationsHeader: "Unused # sessions",
})

// Data key should match key as used in data retrieved from backend.
// If column should be hidden in mobile, mobileWidth should be undefined.
export default [
  {
    id: "companyname",
    text: messages.companyHeader,
    width: "w-5/12",
    sortableIcon: true,
    mobileWidth: "w-5/12",
    extractAttribute: data => data.companyname,
    render: ({ attribute }) => <span className="font-semibold">{attribute}</span>,
  },
  {
    id: "maxstandard",
    text: messages.unusedStandard,
    width: "w-3/12",
    mobileWidth: "w-3/12",
    extractAttribute: data => data.maxevaluations[EvaluationType.STANDARD],
    render: ({ data }) => {
      const max = data.maxevaluationtypes[EvaluationType.STANDARD] ?? 0
      const unused = data.unusedevaluationtypes[EvaluationType.STANDARD] ?? 0
      if (max > 0) {
        return (
          <span className="font-medium">
            {unused}/{max}
          </span>
        )
      } else {
        return <span className="opacity-50 italic text-xs font-bold">-</span>
      }
    },
  },
  {
    id: "maxeasy",
    text: messages.unusedEasy,
    width: "w-3/12",
    mobileWidth: "w-3/12",
    extractAttribute: data => data.maxevaluations[EvaluationType.EASY],
    render: ({ data }) => {
      const max = data.maxevaluationtypes[EvaluationType.EASY] ?? 0
      const unused = data.unusedevaluationtypes[EvaluationType.EASY] ?? 0
      if (max > 0) {
        return (
          <span className="font-medium">
            {unused}/{max}
          </span>
        )
      } else {
        return <span className="opacity-50 italic text-xs font-bold">-</span>
      }
    },
  },
  {
    id: "action",
    width: "w-1/12",
    noTooltip: true,
    notClickable: true,
    render: data => {
      return <DeletCompanyAction company={data} />
    },
  },
]
