import React, { useEffect, useState } from "react"
import Title from "../../../../layout/title"
import TextInput from "../../../../base/forms/textInput"
import Label from "../../../../base/forms/label"
import Button from "../../../../base/buttons/regular"
import ModalContainer from "../../../../base/modals/modalContainer"
import Loader from "../../../../base/loaders/loader"
import usePatchPerson from "../../../../../utils/hooks/usePatchPerson"
import notifications from "../../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  updatePersonSuccessNotificationTitle: "Person updated!",
  updatePersonSuccessNotificationMessage: "You have successfully updated this person.",
  updatePersonErrorNotificationTitle: "Oops! Something went wrong.",
  updatePersonTitle: "Edit {firstname}'s info",
  updatePersonFirstNameInputLabel: "First name: ",
  updatePersonLastNameInputLabel: "Last name: ",
  updatePersonEmailInputLabel: "Email: ",
  updatePersonFunctionInputLabel: "Function: ",
  updatePersonConfirmButton: "Confirm",
})

const EditHrManagerModal = ({ onClose, person, className }) => {
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setProfile(person)
    setLoading(false)
  }, [])

  const formatMessage = useFormatMessage()
  const handleChange = e => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }

  const { patchPerson, loading: updateLoading } = usePatchPerson()

  /* Handle update of user information */
  const handleUpdate = async () => {
    try {
      await patchPerson(profile)
      notifications.success(
        formatMessage(messages.updatePersonSuccessNotificationTitle),
        formatMessage(messages.updatePersonSuccessNotificationMessage)
      )
      onClose()
    } catch (error) {
      notifications.error(formatMessage(messages.updatePersonErrorNotificationTitle), error.message, error.details)
    }
  }

  return (
    <ModalContainer nestedModal onClose={onClose} className={`bg-white w-128 shadow rounded items-center z-50 ${className}`}>
      <div className="flex flex-col w-full px-12 pb-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Title>{formatMessage(messages.updatePersonTitle, { firstname: person.firstname })}</Title>
            <div className="w-full flex flex-row flex-wrap mb-4">
              <Label className="mr-2" name={formatMessage(messages.updatePersonFirstNameInputLabel)}>
                <TextInput placeholder={person.firstname} onChange={handleChange} name="firstname" value={profile.firstname} />
              </Label>
              <Label className="flex justify-between" name={formatMessage(messages.updatePersonLastNameInputLabel)}>
                <TextInput placeholder={person.lastname} onChange={handleChange} name="lastname" value={profile.lastname} />
              </Label>
            </div>
            <div className="w-full flex flex-row flex-wrap mb-4">
              <Label className="mr-2" name={formatMessage(messages.updatePersonEmailInputLabel)}>
                <TextInput placeholder={person.email} onChange={handleChange} name="email" value={profile.email} />
              </Label>
              <Label name={formatMessage(messages.updatePersonFunctionInputLabel)}>
                <TextInput placeholder={person.theirref1} onChange={handleChange} name="theirref1" value={profile.theirref1} />
              </Label>
            </div>
            <Button primary className="self-center text-center w-3/4" onClick={handleUpdate} loading={updateLoading}>
              {formatMessage(messages.updatePersonConfirmButton)}
            </Button>
          </>
        )}
      </div>
    </ModalContainer>
  )
}

export default EditHrManagerModal
