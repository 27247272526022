import React, { useState } from "react"

import Layout from "../../components/layout"
import Clients from "../../components/atolo/clients"
import ClientModal from "../../components/atolo/clientModal"
import { fetchCompanies } from "../../store/companies/actions"
import { fetchCompany } from "../../store/company/actions"
import { useFetching } from "../../utils/hooks/useFetch"
import AtoloLoginGuard from "../../components/atolo/AtoloLoginGuard"
import { Helmet } from "react-helmet"

const ClientsPage = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [uuidClientSelected, setUuidClientSelected] = useState("")
  const [visible, setVisible] = useState(false)

  /* Fetch all companies */
  useFetching(fetchCompanies())
  /* Fetch the client */
  useFetching(fetchCompany(uuidClientSelected), uuidClientSelected)

  const handleRowClick = clientUUID => {
    setUuidClientSelected(clientUUID)
    setVisible(true)
  }

  return (
    <AtoloLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Clients</title>
      </Helmet>
      <Layout type="atolo" onSearch={setSearchQuery} searchQuery={searchQuery}>
        <div className="container flex justify-center flex-1 h-full max-w-4xl w-4/5 md:h-auto md:pt-8 md:pb-20">
          <Clients
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            handleRowClick={handleRowClick}
            className="w-full"
            setUuidClientSelected={setUuidClientSelected}
          />
          {uuidClientSelected !== "" && (
            <ClientModal
              className="bg-white absolute md:w-8/12 md:h-auto"
              clientUUID={uuidClientSelected}
              visible={visible}
              onClose={() => setVisible(false)}
            />
          )}
        </div>
      </Layout>
    </AtoloLoginGuard>
  )
}

export default ClientsPage
