import React from "react"
import TextInput from "../../../../../base/forms/textInput"
import Select from "../../../../../base/forms/select"
import { dropdownLanguageOptions } from "../../../../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  firstNamePlaceholder: "First Name",
  lastNamePlaceholder: "Last Name",
  emailPlaceholder: "Email",
})

const NewHrManager = ({ setPerson, person, errors, showErrors }) => {
  const handleChange = e => {
    setPerson({ ...person, [e.target.name]: e.target.value })
  }

  const formatMessages = useFormatMessage()

  return (
    <div className="flex flex-col w-128 md:flex-row align-center justify-center ">
      <TextInput
        placeholder={formatMessages(messages.firstNamePlaceholder)}
        name="firstname"
        value={person.firstname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.firstname}
        errors={errors.firstname}
        className="md:pl-0 mr-2"
      />
      <TextInput
        placeholder={formatMessages(messages.lastNamePlaceholder)}
        name="lastname"
        value={person.lastname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.lastname}
        errors={errors.lastname}
        className="mr-2"
      />
      <TextInput
        placeholder={formatMessages(messages.emailPlaceholder)}
        name="email"
        value={person.email}
        onChange={handleChange}
        required
        invalid={showErrors && errors.email}
        className="mr-2"
        errors={errors.email}
      />

      <Select
        value={person.commlangcode}
        name="commlangcode"
        onChange={commlangcode => setPerson({ ...person, commlangcode })}
        options={dropdownLanguageOptions}
        className="w-1/4 md:w-1/6 hidden md:flex"
        required
        invalid={showErrors && errors.commlangcode}
      />
    </div>
  )
}

export default NewHrManager
