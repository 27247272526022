import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import NewPerson from "./components/newHrManager"
import { getErrors, hasErrors } from "./utils/validations"
import Button from "../../../../base/buttons/regular"
import useCreatePerson from "../../../../../utils/hooks/useCreatePerson"
import { forEach } from "../../../../../utils/async/asyncForEach"
import ModalContainer from "../../../../base/modals/modalContainer"
import Title from "../../../../layout/title"
import notifications from "../../../../../utils/notifications"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"
import { defineMessages } from "gatsby-plugin-intl"
import _ from "lodash"

const initialState = [{ firstname: "", lastname: "", email: "", commlangcode: "fr" }]

const messages = defineMessages({
  nameRequiredError: "Required",
  emailInvalidError: "Email is not valid",
  nameShouldBeTextError: "Name should be text.",
  createHrManagerSuccessNotificationTitle: "You have created new HR Manager(s)!",
  createHrManagerSuccessNotificationMessage: "The contact person(s) will receive an email with their account credentials to use the platform.",
  createHrManagerErrorNotificationTitle: "Oops! Something went wrong",
  addNewHrManagerTitle: "Add new HR Managers",
  addAdditionalPersonButton: "Add more users",
  addNewHrManagerSubmitButton: "Create HR manager(s)",
})

const CreateHrManagerModal = ({ companyUUID, onClose }) => {
  const [hrManagers, setHrManagers] = useState(initialState)
  const [showErrors, setShowErrors] = useState(false)

  const formatMessage = useFormatMessage()
  const { createPerson, loading } = useCreatePerson()

  const errorCodes = getErrors(hrManagers)

  // Format error codes to messages
  const errors = errorCodes.map(contactPerson => {
    const errorsContatcperson = {}
    _.forOwn(contactPerson, (attrErrors, key) => {
      let formattedError
      if (attrErrors && attrErrors.length > 0) {
        formattedError = attrErrors.map(error => {
          return formatMessage(messages[error])
        })
      }
      errorsContatcperson[key] = formattedError
    })
    return errorsContatcperson
  })

  const addNewPersonForm = () => {
    const newArrayOfForms = [...hrManagers]

    newArrayOfForms.push({
      firstname: "",
      lastname: "",
      email: "",
      commlangcode: "fr",
    })

    setHrManagers(newArrayOfForms)
  }

  const handleSubmit = async () => {
    if (hasErrors(errors)) {
      setShowErrors(true)

      return
    } else {
      try {
        await forEach(hrManagers, async hrManager => {
          const personUUID = uuid()
          await createPerson(
            {
              uuid: personUUID,
              firstname: hrManager.firstname,
              lastname: hrManager.lastname,
              commlangcode: hrManager.commlangcode,
              commonname: `${hrManager.firstname} ${hrManager.lastname}`,
              email: hrManager.email,
              companyuuid: companyUUID,
              isevaluatedperson: false,
              ishrmanager: true,
              isetolo: false,
              theirref1: "",
              theirref2: "",
            },
            companyUUID
          )
        })
        setHrManagers(initialState)
        setShowErrors(false)
        notifications.success(
          formatMessage(messages.createHrManagerSuccessNotificationTitle),
          formatMessage(messages.createHrManagerSuccessNotificationMessage)
        )
      } catch (err) {
        notifications.error(formatMessage(messages.createHrManagerErrorNotificationTitle), err.message, err.details)
      } finally {
        onClose()
      }
    }
  }
  return (
    <ModalContainer nestedModal visible={true} onClose={onClose}>
      <div className="flex flex-col w-3/4 md:w-auto p-10 pt-0">
        <Title>{formatMessage(messages.addNewHrManagerTitle)}</Title>
        {hrManagers.map((hrManager, idx) => (
          <NewPerson
            key={idx}
            setPerson={hrm => {
              const updatedListOfHRManagers = [...hrManagers]
              updatedListOfHRManagers[idx] = hrm
              setHrManagers(updatedListOfHRManagers)
            }}
            person={hrManager}
            errors={errors && errors[idx]}
            showErrors={showErrors}
          />
        ))}
        <div className="flex flex-row items-center justify-center cursor-pointer mt-2" onClick={addNewPersonForm}>
          <p className="text-gray-700 text-sm font-medium">{formatMessage(messages.addAdditionalPersonButton)}</p>
        </div>
        <div className="w-full  flex items-center">
          <Button primary onClick={handleSubmit} loading={loading}>
            {formatMessage(messages.addNewHrManagerSubmitButton)}
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}
export default CreateHrManagerModal
