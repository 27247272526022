import React from "react"
import _ from "lodash"
import GreenLine from "../../../../layout/greenLine"
import Attribute from "./attribute"

// Headers Interface

// const headers = [
//   {
//     text: "Name",
//     width: "w-2/12",
//     mobileWidth?: "w-3/12"
//     sortableIcon?: true,
//   },
// ]

const TableHeader = ({ headers, sortedColumns, onColumnSortClicked, noGreenLine }) => {
  return (
    <>
      {!noGreenLine && <GreenLine />}
      <div className="flex">
        <div className={`flex font-sans text-xs w-full`}>
          {headers.map(header => (
            <Attribute
              key={JSON.stringify(header)}
              header={header}
              onSort={() => onColumnSortClicked(header)}
              sortDirection={_.get(
                sortedColumns.find(state => state.header.id === header.id),
                "direction",
                "none"
              )}
            />
          ))}
        </div>
      </div>

      <div className="bg-gray-300 h-px" />
    </>
  )
}

export default TableHeader
