import _ from "lodash"

export const linkHeaderToData = (headers, row) => {
  return headers.map(header => ({
    ...header,
    data: row,
    attribute: header.extractAttribute && header.extractAttribute(row),
    text: header.extractAttribute ? header.extractAttribute(row) : _.get(row, header.dataKey),
    uuid: row.uuid,
    personuuid: row.personuuid,
    noTooltip: header.noTooltip,
    row,
  }))
}
