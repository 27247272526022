import React, { useState } from "react"
import { MdEdit } from "react-icons/md"
import _ from "lodash"
import companyLogo from "./assets/user.png"
import EditCompanyModal from "./components/editCompanyModal"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"
import { getLevelEvaluationTypeNameForType, getLevelEvaluationTypes } from "src/utils/evaluation-type-utils"

const messages = defineMessages({
  companyInfoMaximumEvaluations: "Max # Evaluations:",
  companyInfoUnusedEvaluations: "Unused # Evaluations:",
  companyInfoUsedEvaluations: "Used # Evaluations:",
  companyInfoTestNotAvailable: "not available",
  companyInfoTestAvailable: "available",
})

const CompanyInfo = ({ company }) => {
  const [edit, setEdit] = useState(false)
  const formatMessage = useFormatMessage()

  const types = getLevelEvaluationTypes()

  return (
    <>
      <div className="flex flex-row items-center justify-center mb-10">
        <div className="flex items-center w-16 h-16 md:w-24 md:h-24 pt-6">
          <img src={companyLogo} className="opacity-50 w-16 h-16 md:w-24 md:h-24 mt-2" />
        </div>
        <div className="flex flex-col justify-center text-center ml-10">
          <div className="w-full flex flex-row items-center justify-start">
            <div className="md:text-xl font-semibold justify-center flex">{company.companyname}</div>
            <MdEdit className="ml-2 opacity-25 cursor-pointer" size={14} onClick={() => setEdit(true)} />
          </div>
          <div className="flex flex-col justify-start items-start">
            {types.map(type => (
              <div key={type} className="flex items-center text-sm justify-center font-medium">
                <span className="mr-1 font-semibold">{_.startCase(getLevelEvaluationTypeNameForType(type))}:</span>
                {company.maxevaluationtypes[type] > 0 ? (
                  <span>
                    {company.unusedevaluationtypes[type] ?? 0}/{company.maxevaluationtypes[type] ?? 0}{" "}
                    {formatMessage(messages.companyInfoTestAvailable)}
                  </span>
                ) : (
                  <span className="opacity-50 italic">{formatMessage(messages.companyInfoTestNotAvailable)}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {edit && <EditCompanyModal onClose={() => setEdit(false)} company={company} />}
    </>
  )
}

export default CompanyInfo
