import React from "react"
import { useSelector } from "react-redux"
import Search from "../../layout/search"
import headers from "./utils/headers"
import PaginatedTable from "../../base/paginated-table"
import { companiesSelector } from "../../../store/companies/selectors"
import { searchClients, parseClients } from "./utils/utils"
import { useFetching } from "../../../utils/hooks/useFetch"
import { fetchCompanies } from "../../../store/companies/actions"

const Clients = ({ className, searchQuery, handleRowClick, onSearch }) => {
  useFetching(fetchCompanies())

  /* Get companies from store */
  const { results, loading } = useSelector(companiesSelector)

  /* Parse clients data, deduct seller email and seller full name */
  let clients
  if (results && results.length > 0) {
    clients = parseClients(results)

    /* Search and sort */
    if (searchQuery !== "") {
      clients = searchClients(searchQuery, clients)
    }
  }

  return (
    <div className={`flex flex-col items-center  ${className}`}>
      <Search onSearch={onSearch} value={searchQuery} className="mb-5 self-start" />
      <PaginatedTable data={clients} headers={headers} loading={loading} onRowClick={handleRowClick} searchQuery={searchQuery} />
    </div>
  )
}

export default Clients
